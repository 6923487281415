import React, { useEffect, useState } from 'react';

// Function to get a cookie by name
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
}

function Leaderboard() {
    const [leaderboard, setLeaderboard] = useState([]);
    const [personalStats, setPersonalStats] = useState(null);
    const [page, setPage] = useState(1);
    const [limit] = useState(10); // Number of items per page
    const [loading, setLoading] = useState(true); // Add loading state

    useEffect(() => {
        const fetchLeaderboard = async () => {
            try {
                setLoading(true); // Set loading to true before fetching data
                const response = await fetch(`/leaderboard?page=${page}&limit=${limit}`, {
                    method: 'POST',
                    // Include token and hc_token cookies as data if necessary
                });
                const data = await response.json();
                setLeaderboard(data.leaderboard);
                setLoading(false); // Set loading to false after data is fetched
            } catch (error) {
                console.error('Error fetching leaderboard:', error);
                setLoading(false); // Set loading to false in case of error
            }
        };

        const fetchPersonalStats = async () => {
            try {
                const response = await fetch('/personal-stats', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        token: getCookie('hc_token')
                    })
                });
                const data = await response.json();
                setPersonalStats(data);
            } catch (error) {
                console.error('Error fetching personal stats:', error);
            }
        };

        fetchLeaderboard();
        fetchPersonalStats();
    }, [page, limit]);

    return (
        <div>
            <h1 className="text-center neonText">Deathmatch Game Leaderboard</h1>
            {loading ? (
                <div className="spinner"></div>
            ) : (
                <>
                    <div className="personal-stats">
                        <h2 className="text-center neonText">Personal Stats</h2>
                        {personalStats ? (
                            <table className="table table-dark text-center">
                                <thead>
                                    <tr>
                                        <th>Games Played</th>
                                        <th>Games Won</th>
                                        <th>Kills</th>
                                        <th>Bot Kills</th>
                                        <th>Headshots</th>
                                        <th>Bot Headshots</th>
                                        <th>Deaths</th>
                                        <th>Bot Deaths</th>
                                        <th>Assists</th>
                                        <th>Bot Assists</th>
                                        <th>Level</th>
                                        <th>XP</th>
                                        <th>Progress to Next Level</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>{personalStats.played}</td>
                                        <td>{personalStats.won}</td>
                                        <td>{personalStats.kills}</td>
                                        <td>{personalStats.bot_kills}</td>
                                        <td>{personalStats.headshots}</td>
                                        <td>{personalStats.bot_headshots}</td>
                                        <td>{personalStats.deaths}</td>
                                        <td>{personalStats.bot_deaths}</td>
                                        <td>{personalStats.assists}</td>
                                        <td>{personalStats.bot_assists}</td>
                                        <td>{personalStats.level}</td>
                                        <td>{personalStats.xp}</td>
                                        <td>{personalStats.progressPercent}%</td>
                                    </tr>
                                </tbody>
                            </table>
                        ) : (
                            <p>Loading personal stats...</p>
                        )}
                    </div>
                    <h2 className="text-center neonText">Leaderboard</h2>
                    <h3>Update your Handcash display name and redo your wallet connect to appear on the leaderboard.</h3>
                    <table className="table table-dark text-center">
                        <thead>
                            <tr>
                                <th>Rank</th>
                                <th>Level</th>
                                <th>Player</th>
                                <th>Kills</th>
                                <th>Bot Kills</th>
                                <th>Headshots</th>
                                <th>Bot Headshots</th>
                                <th>Deaths</th>
                                <th>Bot Deaths</th>
                                <th>Assists</th>
                                <th>Bot Assists</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaderboard
                                .sort((a, b) => b.kills - a.kills) // Sort by "kills" in descending order
                                .map((user, index) => (
                                    <tr key={index}>
                                        <td>{(page - 1) * limit + index + 1}</td>
                                        <td>{user.level}</td>
                                        <td>{user.displayName}</td>
                                        <td>{user.kills}</td>
                                        <td>{user.bot_kills}</td>
                                        <td>{user.headshots}</td>
                                        <td>{user.bot_headshots}</td>
                                        <td>{user.deaths}</td>
                                        <td>{user.bot_deaths}</td>
                                        <td>{user.assists}</td>
                                        <td>{user.bot_assists}</td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div className="pagination">
                        <button onClick={() => setPage(page - 1)} disabled={page === 1}>Previous</button>
                        <button onClick={() => setPage(page + 1)}>Next</button>
                    </div>
                </>
            )}
        </div>
    );
}

export default Leaderboard;