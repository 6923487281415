import React, { useState, useEffect } from 'react';

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(';').shift();
}

function hcclick() {
  // try to get ?login_code= from url
  const urlParams = new URLSearchParams(window.location.search);
  const login_code = urlParams.get('login_code');
  // if window location is localhost:
  if (window.location.href.includes("localhost")) {
    window.location.href = "https://app.handcash.io/#/authorizeApp?appId=663450f13bc92f2bff7065af"
    return
  }

  if (login_code) {
    window.location.href = "https://app.handcash.io/#/authorizeApp?appId=65de4bfb31087fc4de1625d0&login_code=" + login_code;
    return
  }

  window.location.href = "https://app.handcash.io/#/authorizeApp?appId=65de4bfb31087fc4de1625d0"
  return
}

class HCButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedIn: false,
    };
  }

  componentDidMount() {
    // Check if the user is logged in
    const hcToken = getCookie('hc_token');
    if (hcToken) {
      this.setState({ loggedIn: true });
    }
  }

  handleClick = () => {
    if (this.state.loggedIn) {
      // Log out logic
      document.cookie = 'hc_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      this.setState({ loggedIn: false });
    } else {
      // Log in logic
      hcclick();
    }
  };

  render() {
    return (
      <button
        id="hcbutton"
        onClick={this.handleClick}
        className={`btn my-2 ${this.state.loggedIn ? 'btn-danger' : 'btn-success'}`}
      >
        {this.state.loggedIn ? 'Log Out HandCash' : 'Connect HandCash'}
      </button>
    );
  }
}

export default HCButton;